<template>
  <div class="banxin">
    <!--  申请信息-->
    <div style="margin-bottom: 20px" class="tt df">
      <div class="suxian"></div>
      填写申请信息
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="form-box"
    >
      <el-form-item label="售后类型" prop="operationType">
        <el-select v-model="ruleForm.operationType" placeholder="请选择售后类型" @change="typeChange">
          <el-option v-for="(item,index) in afterSaleType" :key="index" :label="item.name" :value="item.type" />
        </el-select>
      </el-form-item>
      <el-form-item label="售后理由" prop="region">
        <el-select v-model="ruleForm.region" placeholder="请选择退货理由" :disabled="!ruleForm.operationType">
          <el-option v-for="(item,index) in reasonList" :key="index" :label="item.value" :value="item.value" />
        </el-select>
      </el-form-item>
    </el-form>

    <!-- 选择配送方式-->
    <div class="tt df">
      <div class="suxian"></div>
      选择配送方式
    </div>
    <div class="distribution">
      <span>配送方式</span>
      <el-radio-group v-model="ruleForm.distributionMode">
        <el-radio :label="2">自行回寄</el-radio>
        <el-radio :label="1">上门取货</el-radio>
      </el-radio-group>
    </div>
    <div class="distribution-info">
      <el-select v-show="ruleForm.distributionMode == 1" v-model="selectAddress" placeholder="请选择地址">
        <el-option v-for="(item,index) in customerAddress" :key="index" :label="item.contacts + item.phone + item.address"  :value="index">
          <span>{{ item.contacts }} </span>
          <span>{{ item.phone }}</span>
          <span>{{ item.address }}</span>
        </el-option>
      </el-select>
      <!-- <div v-show="distributionMode == 0" class="logistics-info">
        <span>物流公司：<el-input placeholder="请输入物流公司"/></span>
        <span>物流单号：<el-input placeholder="请输入物流单号"/></span>
      </div> -->
    </div>

    <!-- 确认退货商品-->
    <div style="margin-top: 40px" class="tt df">
      <div class="suxian"></div>
      确认退货商品信息
    </div>
    <el-form :model="confirmGoodsQuery" ref="queryForm" size="small" :inline="true" label-width="68px">
      <el-form-item label="厂家名称" prop="manufacturer">
        <el-input
          v-model="confirmGoodsQuery.manufacturer"
          placeholder="请输入厂家名称"
          clearable
          maxlength="20"
          @keyup.enter.native="confirmGoodsSearch"
        />
      </el-form-item>
      <el-form-item label="商品名称" prop="productName">
        <el-input
          v-model="confirmGoodsQuery.productName"
          placeholder="请输入商品名称"
          clearable
          maxlength="20"
          @keyup.enter.native="confirmGoodsSearch"
        />
      </el-form-item>
      <el-form-item class="right">
        <el-button type="success" size="medium" @click="confirmGoodsSearch">搜索</el-button>
        <el-button size="medium" @click="resetConfirmGoodsSearch">重置</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-button type="success" size="medium" @click="removeConfirmGoods('all')">移除全部</el-button>
      <el-button type="success" size="medium" @click="removeConfirmGoods('page')">移除当前页</el-button>
      <el-button type="success" size="medium" class="right" @click="showAddList">添加售后商品</el-button>
    </div>

    <div class="list">
      <el-table
        :header-cell-style="{ background: '#F7F8FA', fontSize: '12px' }"
        :data="confirmOrderList"
        tooltip-effect="dark"
        style="width: 100%"
        height="400px"
      >
        <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
        <el-table-column prop="chkdjbh" label="随货同行单" width="120" align="center"></el-table-column>
        <el-table-column label="商品" align="center" width="400">
          <template slot-scope="scope">
            <Detail-Card :rows="scope.row"> </Detail-Card>
          </template>
        </el-table-column>
        <el-table-column prop="pchao" label="批号" width="120" align="center"></el-table-column>
        <el-table-column prop="productQuantity" label="可退货数量" width="120" align="center"></el-table-column>
        <el-table-column prop="productPrice" label="价格(元)" width="120" align="center"></el-table-column>
        <el-table-column width="120" align="center" label="小计(元)">
          <template slot-scope="scope">
            <div class="after-num">{{ (scope.row.productPrice * scope.row.selNum).toFixed(2) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="auto">
          <template slot-scope="scope">
            <div style="display: flex;align-items: center;">
              <div class="back-info" @click="removeConfirmGoods('current',scope.row)">移除</div>
              <div class="back">
                退货数量
                <el-input-number
                  @change="changeConfirmNum(scope.row)"
                  step-strictly
                  style="width: 80px; margin-left: 10px"
                  size="small"
                  :min="0"
                  v-model="scope.row.selNum"
                ></el-input-number>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="confirmGoodsSearch"
        @current-change="confirmGoodsSearch"
        :current-page.sync="confirmGoodsQuery.pageNum"
        :page-size="confirmGoodsQuery.pageSize"
        layout="prev, pager, next, jumper"
        :total="confirmOrderListTotal">
      </el-pagination>
    </div>
    <div class="button">
      <div class="txt">预计退货总数：<span class="num"> {{ totalBack }} </span></div>
      <div class="txt">预计退货总金额：<span class="num"> {{ formatMoney(totalMoney,'￥') }} </span></div>
      <el-button type="success" @click="submitForm()" :disabled="totalBack == 0">提交申请</el-button>
      <el-button @click="$router.push('/user/myOrder')">取消申请</el-button>
    </div>

    <!-- 选择售后商品弹窗-->
    <el-dialog title="添加售后商品" width="1230px" :visible.sync="showAfterSaleGoods" :close-on-click-modal="false">
      <div class="tt df">
        <div class="suxian"></div>
        可退商品列表
      </div>
      <el-form :model="goodsQuery" ref="queryForm" size="small" :inline="true" label-width="68px">
        <el-form-item label="厂家名称" prop="manufacturer">
          <el-input
            v-model="goodsQuery.manufacturer"
            placeholder="请输入厂家名称"
            clearable
            maxlength="20"
            @keyup.enter.native="getRefundableProductFun"
          />
        </el-form-item>
        <el-form-item label="商品名称" prop="productName">
          <el-input
            v-model="goodsQuery.productName"
            placeholder="请输入商品名称"
            clearable
            maxlength="20"
            @keyup.enter.native="getRefundableProductFun"
          />
        </el-form-item>
        <el-form-item class="right">
          <el-button type="success" size="medium" @click="getRefundableProductFun">搜索</el-button>
          <el-button size="medium" @click="resetGoodsSearch">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="success" size="medium" @click="conformRefundGoods">添加所选</el-button>
        <el-button size="medium">取消</el-button>
      </div>

      <div class="list">
        <el-table
          :header-cell-style="{ background: '#F7F8FA', fontSize: '12px' }"
          :data="goodsSearchValue"
          tooltip-effect="dark"
          style="width: 100%"
          height="400px"
          @selection-change="handleSelectionChange"
          ref="table"
        >
          <el-table-column type="selection" width="55" align="center" :selectable="selectableFun"></el-table-column>
          <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
          <el-table-column prop="chkdjbh" label="随货同行单" width="80" align="center"></el-table-column>
          <el-table-column label="商品" align="center" width="380">
            <template slot-scope="scope">
              <Detail-Card :rows="scope.row"> </Detail-Card>
            </template>
          </el-table-column>
          <el-table-column prop="pchao" label="批号" width="100" align="center"></el-table-column>
          <el-table-column prop="productQuantity" label="可退货数量" width="100" align="center"></el-table-column>
          <el-table-column prop="productPrice" label="价格(元)" width="100" align="center"></el-table-column>
          <el-table-column width="120" align="center" label="小计(元)">
            <template slot-scope="scope">
              <div class="after-num">{{ (scope.row.productPrice * scope.row.selNum).toFixed(2) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <div class="back">
                退货数量
                <el-input-number
                  @change="changeBuyNum(scope.row)"
                  step-strictly
                  style="width: 80px; margin-left: 10px"
                  size="small"
                  :min="0"
                  v-model="scope.row.selNum"
                ></el-input-number>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="getRefundableProductFun"
          @current-change="getRefundableProductFun"
          :current-page.sync="goodsQuery.pageNum"
          :page-size="goodsQuery.pageSize"
          layout="prev, pager, next, jumper"
          :total="goodsSearchValueTotal">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DetailCard from "@/components/custom-base/custom-product-detail-card";
import emptyGoodsImg from "@/assets/images/empty-product.jpg";
import { orderDetail, submitCheck, getRefundableProduct, saveSelectRefundProduct, getSelectRefundProduct, removeSelectRefundProduct,removeAllSelectRefundProduct,
  updateSelectRefundProduct, getRefundProductTotal} from "@/request/Orders/order";
import { shoppingMall } from "@/request/users/users";
import {formatMoney} from '@/utils/tool'
export default {
  components: {
    DetailCard,
  },
  data() {
    return {
      formatMoney:formatMoney,
      afterSaleType:[
        {
          type: '1',
          name: '退货',
          reason: [
            {value: '产品浸水严重'},
            {value: '产品被挤压破损'},
            {value: '确认商品已停售'},
            {value: '其他'}
          ]
        },
        {
          type: '2',
          name: '补差',
          reason: [
            {value: '商品数量缺失'},
            {value: '商品种类不齐'},
            {value: '其他'}
          ]
        },
        {
          type: '4',
          name: '召回',
          reason: [
            {value: '商品被回收'},
            {value: '药检质量不合格'},
            {value: '其他'}
          ]
        }
      ],
      reasonList: [],
      
      // 客户地址
      customerAddress:[],
      selectAddress:null,
      //From规则
      ruleForm: {
        operationType: "",
        region: "",
        distributionMode:2
      },
      rules: {
        region: [
          { required: true, message: "请选择售后理由", trigger: "change" },
        ],
        operationType: [
          { required: true, message: "请选择售后类型", trigger: "change" },
        ],
      },
      //空图片
      emptyGoodsImg,
      //列表数据
      oderList: {},
      // 退货商品相关
      goodsQuery:{
        pageNum:1,
        pageSize:10,
        orderId:this.$route.query.id,
        manufacturer:'',
        productName:''
      },
      // 售后商品弹窗
      showAfterSaleGoods:false,
      afterSaleSelection:[],
      //  确认后售后商品信息
      confirmOrderList:[],
      confirmOrderListTotal:0,
      // 确认退货商品查询参数
      confirmGoodsQuery:{
        pageNum:1,
        pageSize:10,
        orderId:this.$route.query.id,
        manufacturer:'',
        productName:''
      },
      //  订单商品相关
      goodsSearchValue:[],
      goodsSearchValueTotal:0,

      // 总数
      totalBack: 0,
      totalMoney: 0
    };
  },
  created() {
    this.getList();
    this.getcustomerList();
    this.confirmGoodsSearch();
  },
  methods: {
    getList() {
      orderDetail({ orderId: this.$route.query.id }).then((res) => {
        if (res.code === 200) {
          this.oderList = res.data;
        }
      });
      //
    },

    // 修改类型
    typeChange() {
      this.ruleForm.region = '';
      this.afterSaleType.map(i => {
        if (this.ruleForm.operationType === i.type) {
          this.reasonList = i.reason
        }
      })
    },
    //提交,并且校验
    submitForm() {
      if(!this.ruleForm.region){
        this.$message.warning('请补全退货原因')
        return
      }
      if(this.ruleForm.distributionMode == 1 && (!this.selectAddress && this.selectAddress != 0)){
        this.$message.warning('请选择上门取货地址')
        return
      }

      let data = {
        operationType: this.ruleForm.operationType,
        applyCause: this.ruleForm.region,
        orderSn: this.oderList.order.orderSn,
        applyType: 2,
        orderId: this.$route.query.id,
        refundType: 2,
        userId: this.$store.state.owerId,
        distributionMode:this.ruleForm.distributionMode
      };
      if(this.ruleForm.distributionMode == '1'){
        data.senderName = this.customerAddress[this.selectAddress].contacts
        data.senderAddress = this.customerAddress[this.selectAddress].address
        data.phone = this.customerAddress[this.selectAddress].phone
      }
      console.log(data)

      submitCheck(data).then((res) => {
        if (res.code === 200) {
          this.$message.success("申请成功,即将跳转到订单列表");
          setTimeout(() => {
            this.$router.push("/user/myOrder");
          }, 1000);
        }
      });
    },
    changeBuyNum(row) {
      if (row.selNum > row.productQuantity) {
        this.$message.warning("退货数量不能大于购买数量");
        this.$nextTick(() => {
          row.selNum = row.productQuantity;
        });
        return;
      }
    },
    //  修改选定退货商品数量
    changeConfirmNum(row) {
      if (row.selNum > row.productQuantity) {
        this.$message.warning("退货数量不能大于购买数量");
        this.$nextTick(() => {
          row.selNum = row.productQuantity;
        });
        return;
      }
      let data = {
        id:row.id,
        selNum:row.selNum
      }
      updateSelectRefundProduct(data).then(res => {
        if(res.code == 200){
          this.getTotal()
        }
      })
    },

    //  商品勾选
    handleSelectionChange(val){
      this.afterSaleSelection = val
    },
    // 添加售后商品
    conformRefundGoods(){
      if(this.afterSaleSelection.length == 0){
        this.$message.warning('您未勾选任何商品')
        return
      }

      this.$confirm('确认添加所选售后商品？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        saveSelectRefundProduct(this.afterSaleSelection).then(res => {
          if(res.code == 200){
            this.showAfterSaleGoods = false
            this.$refs.table.clearSelection()
            this.confirmGoodsSearch()

          }
        })
      }).catch(() => {
        this.$refs.table.clearSelection() 
      });
    },

    //  确认售后商品表相关操作
    removeConfirmGoods(type,row){
      if(type == 'all'){
        this.$confirm('确认移除全部退货商品？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {orderId:this.$route.query.id}
          removeAllSelectRefundProduct(data).then(res => {
            if(res.code == 200){
              this.confirmGoodsSearch()
            }
          })
        }).catch(() => {
            
        });
      }

      if(type == 'current'){
        this.$confirm('确认移除该退货商品？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {ids:[row.id]}
          removeSelectRefundProduct(data).then(res => {
            if(res.code == 200){
              this.confirmGoodsSearch()
            }
          })
        }).catch(() => {
            
        });
      }

      if(type == 'page'){
        this.$confirm('确认移除当前页所有退货商品？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {ids:[]}
          this.confirmOrderList.map(i => {
            data.ids.push(i.id)
          })
          removeSelectRefundProduct(data).then(res => {
            if(res.code == 200){
              this.confirmGoodsSearch()
            }
          })
        }).catch(() => {
            
        });
      }
    },

    // 列表商品筛选
    resetGoodsSearch(){
      this.goodsQuery.manufacturer = ''
      this.goodsQuery.productName = ''
      this.getRefundableProductFun()
    },

    // 确认退货商品列表
    confirmGoodsSearch(){
      getSelectRefundProduct(this.confirmGoodsQuery).then(res => {
        if(res.code == 200){
          this.confirmOrderList = res.data.applyList.rows
          this.confirmOrderListTotal = res.data.applyList.total
          this.getTotal()
        }
      })
    },
    resetConfirmGoodsSearch(){
      this.confirmGoodsQuery.manufacturer = ''
      this.confirmGoodsQuery.productName = ''
      this.confirmGoodsSearch()
    },

    //  获取可退商品列表
    showAddList(){
      this.showAfterSaleGoods = true
      this.getRefundableProductFun()
    },
    selectableFun(row) {
      return row.productQuantity
    },
    getRefundableProductFun(){
      getRefundableProduct(this.goodsQuery).then(res => {
        if(res.code == 200){
          res.data.rows.map((item) => {
            item.selNum = 1;
            if (item.productQuantity === 0) item.selNum = 0;
          });
          this.goodsSearchValue = res.data.rows
          this.goodsSearchValueTotal = res.data.total
        }
      })
    },
    getTotal(){
      getRefundProductTotal({ orderId: this.$route.query.id }).then(res => {
        if(res.code == 200){
          this.totalBack = res.data.totalNum
          this.totalMoney = res.data.totalAmt
        }
      })
    },

    // 获取收货地址
    getcustomerList() {
      shoppingMall().then((res) => {
        if (res.code === 200) {
          this.customerAddress = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-table th.el-table__cell {
    font-size: 12px;
  }
  .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #595a6d;
    font-size: 14px;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  .el-select .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 32px;
    line-height: 32px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 260px;
  }
  .el-input-number__decrease {
    left: 1px;
    border-radius: 4px 0 0 4px;
    border-right: 0;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 4px;
    text-align: center;
    border: 1px #f5f5f5 solid;
    background: #fff;

    cursor: pointer;
    font-size: 13px;
  }
  .el-input-number--small .el-input__inner {
    color: #26C487;
    border: 0;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
  }
  .el-input-number__increase {
    right: 1px;
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }
}

.form-box{
  padding-left:20px;
  display: flex;
}
.distribution{
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #595A6D;
  padding-left:20px;
  .el-radio-group{
    margin-left:50px;
  }
}
.distribution-info{
  margin:25px 0 0 125px;
  ::v-deep .el-select{
    .el-input__inner{
      width:700px;
      height:40px;
    }
  }
}
.logistics-info{
  ::v-deep .el-input{
    display: inline-block;
    width:250px;
    .el-input__inner{
      width: 200px;
    }
  }
}
.button {
  text-align: right;
  margin-top:40px;
  .txt{
    display: inline-block;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #6b6c7b;
    margin-right:50px;
}
}
.footer {
  margin-top: 30px;
  margin-bottom: 50px;
  width: 100%;
  height: 50px;
  padding-right: 30px;
  line-height: 50px;
  background: #f7f8fa;
  text-align: right;
  opacity: 1;
  }
.footer {
  margin-top: 30px;
  margin-bottom: 50px;
  width: 100%;
  height: 50px;
  padding-right: 30px;
  line-height: 50px;
  background: #f7f8fa;
  text-align: right;
  opacity: 1;
  .num {
    width: 18px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #e33233;
  }
}

.banxin {
  padding: 30px 30px;
  box-sizing: border-box;
}
.suxian {
  width: 3px;
  height: 16px;
  background: #26c487;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
}
.tt {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #202d40;
}
.after-num {
  display: flex;
  flex-direction: column;
  align-items: center;
  .num {
    width: 100%;
    margin-top: 70px;
    margin-bottom: 50px;
  }
}
.back {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #26C487;
  display: flex;
  align-items: center;
}
.back-info{
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #26C487;
  margin-right:20px;
  cursor: pointer;
}

.cardlist {
  padding: 10px;
}
.lii {
  display: flex;
  .card {
    text-align: center;
    border: 1px solid @hui;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
  }
  .goodsDetail {
    flex: 1;
    margin-left: 16px;
    .h1 {
      font-size: 16px;
      font-weight: 500;
    }
    .info {
      font-size: 14px;
      margin-bottom: 4px;
      color: #adafad;
    }
  }
}

.el-form-item{
  width:32%;
  white-space: nowrap;
  ::v-deep .el-form-item__label{
    text-align: left;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #595A6D;
  }
  ::v-deep .el-form-item__content{
    width:180px;
  }
}
.right{
  text-align: right;
  float: right;
  margin-right:0;
}
.center{
  text-align: center;
}

::v-deep .el-dialog__wrapper{
  .el-dialog__header{
    background: #F5F5F5;
  }
  .el-dialog__title{
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #202D40;
  }
  .el-dialog__body{
    padding:20px;
  }
}

.list{
  margin-top:20px;
}

.el-pagination{
  text-align: right;
  margin-top:10px;
}
</style>
